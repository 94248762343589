import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../components/header/Header90Days";
import Footer from "../components/footer/Footer90Days";

const MaintenancePage = () => {
  let [searchParams] = useSearchParams();
  const first_name = searchParams.get("first_name") || "";
  const last_name = searchParams.get("last_name") || "";

  const [maintenanceEndTime, setMaintenanceEndTime] = useState("");
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const endTime = new Date();
    endTime.setHours(endTime.getHours() + 4);
    const formatter = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short"
    });

    setMaintenanceEndTime(formatter.format(endTime));
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(formatter.format(now));
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header
        userName={first_name + " " + last_name}
        userImage=""
        header={false}
      />

      <div
        title="90 Day Follow Up"
        className="md:hidden py-10 block text-xl bg-gray-100 sm:text-2xl md:text-4xl text-center text-gray-800 font-bold"
      >
        90 Day Follow Up
      </div>

      <div className="flex-grow flex items-center justify-center bg-gray-100">
        <div className="md:max-w-4xl w-full mx-4 md:mx-auto p-8 bg-white shadow-2xl rounded-lg border border-gray-200">
          <div className="text-center">
            <div className="mb-8">
              <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-yellow-100 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 text-yellow-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </div>
              <h2 className="text-3xl font-bold text-gray-800 mb-2">
                System Maintenance
              </h2>
              <p className="text-lg text-gray-600 mb-6">
                We're currently performing scheduled maintenance on our
                appointment system.
              </p>
             
            </div>

            <div className="border-t border-gray-200 pt-6">
              <h3 className="text-xl font-semibold mb-4 text-gray-800">
                During this time:
              </h3>
              <ul className="space-y-3 text-left max-w-md mx-auto mb-8">
                <li className="flex items-start">
                  <svg
                    className="h-6 w-6 text-[#00c19c] mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>
                    Our team is upgrading the appointment scheduling system
                  </span>
                </li>
                <li className="flex items-start">
                  <svg
                    className="h-6 w-6 text-[#00c19c] mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>
                    You can still contact customer support via email or phone
                  </span>
                </li>
                <li className="flex items-start">
                  <svg
                    className="h-6 w-6 text-[#00c19c] mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>All existing appointments remain unaffected</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MaintenancePage;
