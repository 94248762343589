import React, { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Select from "react-select";
import { stateOptions } from "../constants/statesLis";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Loader from "../components/Loader";
import { url } from "../constants/url";

const SelectStatePage = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  let selectedStateParam = searchParams.get("state");
  let email = searchParams.get("e");
  let token = searchParams.get("to");
  let type = searchParams.get("ty");
  let pyment = searchParams.get("p");
  let isScheduled = searchParams.get("n") || "0";
  console.log("isScheduled", isScheduled);
  const {
    selectedState,
    first_name: firstName,
    last_name: lastName
  } = useStoreState((state) => state.appointment);

  // Select actions
  const {
    setSelectedState,
    setFirstName,
    setLastName,
    setPhone,
    setData,
    setEmail,
    setToken,
    setUID,
    setType,
    setERRMSG,
    setDates,
    setSelectedTime,
    setAlreadyConfirmedList,
    setAvailableSlots,
    setGroupedData,
    setSelectedDate
  } = useStoreActions((actions) => actions.appointment);

  useEffect(() => {
    if (type) {
      localStorage.setItem("type", type);
      setType(type);
    }
  }, [type]);

  useEffect(() => {
    localStorage.setItem("isScheduled", isScheduled);
  }, [isScheduled]);

  const validateTokenEmail = async () => {
    setLoading(true);
    setEmail(email);
    setData();

    try {
      const response = await fetch(
        `${url}api/v1/prognocis/validate_token_email/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Prognocis-Token": token
          },
          body: JSON.stringify({
            email,
            visit_type: type,
            payment: pyment,
            new: isScheduled === 1 || isScheduled === "1" ? true : false
          })
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          navigate("/404");
        } else {
          throw new Error("Failed to validate token. Please try again.");
        }
      }

      const data = await response.json();
      if (data && data.user_details) {
        const userDetails = data.user_details;
        setFirstName(userDetails.first_name);
        setLastName(userDetails.last_name);
        setPhone(userDetails.phone_number);
        setUID(userDetails.patient_id);
        setEmail(userDetails.email);
        setToken(token);
        localStorage.setItem("token", token);
        localStorage.setItem("first_name", userDetails.first_name);
        localStorage.setItem("last_name", userDetails.last_name);
        localStorage.setItem(
          "name",
          `${userDetails.first_name} ${userDetails.last_name}`
        );
        localStorage.setItem("email", userDetails.email);
      } else {
        navigate("/404");
      }
    } catch (error) {
      navigate("/404");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validateTokenEmail();
  }, []);

  const handleConfirm = () => {
    if (selectedState) {
      setSelectedDate();
      setAvailableSlots([]);
      setERRMSG();
      setDates();
      setSelectedTime();
      setGroupedData();
      setUID();
      navigate("/appointment/select-date");
    }
  };

  const handleStateChange = (selectedOption) => {
    localStorage.setItem("selectedOption", JSON.stringify(selectedOption));
    localStorage.setItem("state", selectedOption?.value);
    setAlreadyConfirmedList([]);
    setSelectedState(selectedOption);
  };

  if (loading) {
    return <Loader loadingText={"Verifying users details"} />;
  }

  return (
    <>
      <Header userName={" "} />
      <div className="flex-grow flex items-center justify-center md:bg-gray-100">
        <div className="md:max-w-4xl md:mx-auto md:w-[80%] p-4 md:p-8 bg-white md:shadow-2xl rounded-lg flex flex-col md:flex-row justify-around md:border border-gray-200 md:space-x-4">
          <div className="flex-1 flex flex-col md:py-10 items-center justify-center space-y-4 p-4">
            <div className="space-y-4">
              <h1 className="text-2xl md:text-4xl font-bold text-center text-gray-800">
                {pyment === "0" && isScheduled === "0"
                  ? "Reschedule your Appointment!"
                  : !isScheduled
                  ? "Reschedule your Appointment!"
                  : "Schedule your Appointment!"}
              </h1>
              <h3 className="text-xl md:text-2xl font-bold text-center text-gray-800">
             
                What state would you like medication shipped to?
              </h3>
              <h4 className="text-sm md:text-md italic text-center text-gray-500">
                This helps us connect you with a licensed provider in your
                state.
              </h4>
            </div>
            <div className="w-full px-4 md:w-96">
              <Select
                options={stateOptions}
                onChange={handleStateChange}
                value={selectedState}
                isClearable
                placeholder="Search and select a state..."
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: 40,
                    borderColor: "rgba(0, 123, 255, 0.2)",
                    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      borderColor: "rgba(0, 123, 255, 0.3)"
                    }
                  }),
                  option: (provided) => ({
                    ...provided,
                    height: "30px",
                    display: "flex",
                    alignItems: "center"
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "120px",
                    overflowY: "auto"
                  })
                }}
              />
            </div>
            <div className="w-full px-4 md:w-96">
              <button
                onClick={handleConfirm}
                className="mt-4 w-full bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded-3xl shadow-lg transition duration-300 ease-in-out"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SelectStatePage;
