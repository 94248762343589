import moment from "moment-timezone";
import { end_daylight, start_daylight } from "../constants/url";
export const formatTime = (date) => {
  const dateString = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  });

  const timeString = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  });

  return `${dateString}, ${timeString}`;
};

export const formatDate = (date) => {
  const dateString = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  });

  return `${dateString}`;
};

export const getDate = (selectedState, currentTime) => {
  return selectedState.timeZone + " " + currentTime;
};

export const getUniqueAppointmentDates = (appointmentsArray, timezone) => {
  const uniqueAppointmentDates = Array.from(
    new Set(appointmentsArray.map((appointment) => appointment.appointmentDate))
  );

  return uniqueAppointmentDates;
};

export const groupByStartTime = (appointments) => {
  const groupedByStartTime = {};
  appointments.forEach((appointment) => {
    const { start_time } = appointment;
    if (!groupedByStartTime[start_time]) {
      groupedByStartTime[start_time] = [];
    }
    groupedByStartTime[start_time].push(appointment);
  });

  return groupedByStartTime;
};

export const validateAndFormatExpiryDate = (expiryDate) => {
  if (!expiryDate) throw new Error("No expiry date provided.");
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [month, year] = expiryDate.split("/").map(Number);
  const fullYear = 2000 + year;
  if (
    fullYear < currentYear ||
    (fullYear === currentYear && month < currentMonth)
  ) {
    return false;
  }

  return true;
};

export const filterNonOverlappingSlots = (appointmentSlots, timeZone) => {
  appointmentSlots.sort((a, b) => {
    let dateA = moment(a.start_time).tz(timeZone);
    let dateB = moment(b.start_time).tz(timeZone);
    return dateA - dateB;
  });

  const nonOverlappingSlots = [];
  let lastEndTime = moment.tz(timeZone);
  appointmentSlots.forEach((slot) => {
    const startTime = moment.tz(slot.start_time, timeZone);
    const endTime = moment.tz(slot.end_time, timeZone);
    if (startTime.isSameOrAfter(lastEndTime)) {
      nonOverlappingSlots.push({
        ...slot,
        appointmentStart: convertCDTtoPDTandFormat(
          startTime,
          timeZone,
          "MM/DD/YYYY, hh:mm A"
        ),
        appointmentEnd: convertCDTtoPDTandFormat(
          endTime,
          timeZone,
          "MM/DD/YYYY, hh:mm A"
        ),
        appointmentDate: convertCDTtoPDTandFormat(
          endTime,
          timeZone,
          "MM/DD/YYYY"
        )
      });
      lastEndTime = endTime.clone();
    }
  });

  return nonOverlappingSlots;
};

// export const convertCDTtoPDTandFormat = (dateTime, zone, frmt) => {
//   const cdtTime = moment(dateTime, "YYYY-MM-DDTHH:mm:ssZ");
//   const pdtTime = cdtTime.clone().tz(zone);
//   return pdtTime.format(frmt);
// };

export const formatDateNow = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const convertCDTtoPDTandFormat = (dateTime, zone, frmt) => {
  const date = new Date(dateTime);


  // const dstStart = new Date(start_daylight);
   const dstStart = new Date("2025-03-09T02:00:00");
   // const dstEnd = new Date(end_daylight);
   const dstEnd = new Date("2025-11-02T02:00:00");

  let applyDST = false;
  if (date >= dstStart && date < dstEnd) {
    applyDST = true;
  }

  if (applyDST) {
    date.setHours(date.getHours() - 1);
  }


  const formatOptions = {
    timeZone: zone, // The target time zone
    weekday: frmt.includes("dddd") ? "long" : undefined,
    year: frmt.includes("YYYY") ? "numeric" : undefined,
    month: frmt.includes("MMMM")
      ? "long"
      : frmt.includes("MM")
      ? "2-digit"
      : undefined,
    day: frmt.includes("DD") ? "2-digit" : undefined,
    hour: frmt.includes("HH") || frmt.includes("hh") ? "numeric" : undefined,
    minute: frmt.includes("mm") ? "2-digit" : undefined,
    second: frmt.includes("ss") ? "2-digit" : undefined,
    hour12: frmt.includes("hh") || frmt.includes("A")
  };

  const timeFormatter = new Intl.DateTimeFormat("en-US", formatOptions);
  const formattedDate = timeFormatter.format(date);

  return formattedDate;
};

// export const convertCDTtoPDTandFormat = (dateTime, zone, frmt) => {
//   const date = new Date(dateTime);
//   console.log("dateTime  after conversion", date);

//   const formatOptions = {
//     timeZone: zone,
//     weekday: frmt.includes("dddd") ? "long" : undefined,
//     year: frmt.includes("YYYY") ? "numeric" : undefined,
//     month: frmt.includes("MMMM")
//       ? "long"
//       : frmt.includes("MM")
//       ? "2-digit"
//       : undefined,
//     day: frmt.includes("DD") ? "2-digit" : undefined,
//     hour: frmt.includes("HH") || frmt.includes("hh") ? "numeric" : undefined,
//     minute: frmt.includes("mm") ? "2-digit" : undefined,
//     second: frmt.includes("ss") ? "2-digit" : undefined,
//     hour12: frmt.includes("hh") || frmt.includes("A")
//   };

//   const timeFormatter = new Intl.DateTimeFormat("en-US", formatOptions);

//   const formattedDate = timeFormatter.format(date);

//   return formattedDate;
// };

export const checkTimeOverlap = (arrayA, arrayB) => {
  for (const objB of arrayB) {
    const { start_time: startTimeB, end_time: endTimeB } = objB;
    for (const objA of arrayA) {
      const { start_time: startTimeA, end_time: endTimeA } = objA;
      if (
        (startTimeB >= startTimeA && startTimeB < endTimeA) ||
        (endTimeB > startTimeA && endTimeB <= endTimeA) ||
        (startTimeA >= startTimeB && startTimeA < endTimeB) ||
        (endTimeA > startTimeB && endTimeA <= endTimeB)
      ) {
        return false;
      }
    }
  }
  return true;
};
